import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppStoreBadge } from '@rikstv/play-common/src/components/appstore/AppStoreBadge';
import { PlayStoreBadge } from '@rikstv/play-common/src/components/appstore/PlayStoreBadge';
import { ButtonLink } from '@rikstv/play-common/src/components/button/ButtonLink';
import { selectIsLoggedIn } from '@rikstv/play-common/src/forces/auth/selectors';
import { FacebookSvg, InstagramSvg } from '@rikstv/play-common/src/icons';
import iconstyle from '@rikstv/play-common/src/icons/icons.module.css';
import { getAppLink, isAndroid, isIOS } from '@rikstv/play-common/src/utils/device/device.utils';
import { SubBody } from '@rikstv/shared-components';
import { webAppLinks } from '@rikstv/shared-strim-paths';

import routerPath, { routerPaths } from '../../router/router.path';

import style from './Footer.module.css';

export const Footer: FC = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  const validPaths = [routerPaths.account, '/package', '/payment', routerPaths.activateTV];
  const hasCenterLayout = validPaths.some(path => location.pathname.startsWith(path));

  return (
    <footer className={`${style.footer} ${hasCenterLayout ? style.centerFooter : ''}`} role="contentinfo">
      <div className={style.inner}>
        <div className={style.section} role="navigation">
          <ul className={style.list}>
            <li>
              <ButtonLink buttonStyle="inline-link" className={style.link} to={webAppLinks.strimetips}>
                <span className={style.linklabel}>Strimetips</span>
              </ButtonLink>
            </li>
            {!isLoggedIn && (
              <li>
                <ButtonLink buttonStyle="inline-link" className={style.link} to={webAppLinks.strommetjenester}>
                  <span className={style.linklabel}>Strømmetjenestene våre</span>
                </ButtonLink>
              </li>
            )}
            <li>
              <ButtonLink buttonStyle="inline-link" className={style.link} to={webAppLinks.hjelp}>
                <span className={style.linklabel}>Hjelp og kontakt</span>
              </ButtonLink>
            </li>
            <li>
              <ButtonLink buttonStyle="inline-link" className={style.link} to={routerPath.companyPersonvern()}>
                <span className={style.linklabel}>Personvern</span>
              </ButtonLink>
            </li>
            <li>
              <ButtonLink buttonStyle="inline-link" className={style.link} to={routerPath.companyTerms()}>
                <span className={style.linklabel}>Vilkår</span>
              </ButtonLink>
            </li>
            <li>
              <ButtonLink buttonStyle="inline-link" className={style.link} to={webAppLinks.ledigeStillinger}>
                <span className={style.linklabel}>Jobb hos oss</span>
              </ButtonLink>
            </li>
          </ul>
          <ul className={style.apps} aria-label="Applikasjoner">
            {isAndroid && (
              <li>
                <PlayStoreBadge href={getAppLink()} />
              </li>
            )}
            {isIOS && (
              <li>
                <AppStoreBadge href={getAppLink()} />
              </li>
            )}
            <li className={style.lastli}>
              <ButtonLink
                buttonStyle="inline-link"
                to="https://www.facebook.com/strim.no/"
                className={iconstyle.iconFacebook}>
                <FacebookSvg />
                <span className="sr-only">Følg oss på Facebook</span>
              </ButtonLink>
              <ButtonLink
                buttonStyle="inline-link"
                to="https://www.instagram.com/strim.no/"
                className={`${iconstyle.iconInstagram} ${style.ig}`}>
                <InstagramSvg />
                <span className="sr-only">Følg oss på Instagram</span>
              </ButtonLink>
            </li>
          </ul>
        </div>
        <div className={style.disclaimer}>
          <div className={style.lastsection}>
            <SubBody className={style.block}>En strømmetjeneste utviklet av RiksTV.</SubBody>
            <SubBody className={style.darkerblock}>
              {new Date().getFullYear()} &copy; Strim / RiksTV AS. Alle rettigheter.
            </SubBody>
          </div>
          <div>
            <ButtonLink
              className={`${iconstyle.iconFacebook} ${style.facebook}`}
              buttonStyle="inline-link"
              to="https://www.facebook.com/strim.no/">
              <FacebookSvg />
              <span className="sr-only">Følg oss på Facebook</span>
            </ButtonLink>
            <ButtonLink
              className={`${iconstyle.iconInstagram} ${style.instagram}`}
              buttonStyle="inline-link"
              to="https://www.instagram.com/strim.no/">
              <InstagramSvg />
              <span className="sr-only">Følg oss på Instagram</span>
            </ButtonLink>
          </div>
        </div>
      </div>
    </footer>
  );
};
